.funds-footer {
  background-color: #122643;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.funds-footer > div {
  padding: 20px;
}

.funds-summary {
  color: #c6cbe1;
}

.funds-summary .label {
  margin-bottom: 5px;
  font-size: smaller;
  white-space: nowrap;
}

.funds-summary .value {
  color: #7596bd;
  font-weight: bolder;
}

.funds-summary .value span {
  color: white;
}

.funds-action {
  display: flex;
  flex-grow: 1;
}

.funds-action button {
  flex-grow: 1;
}

.funds-footer_type {
  color: #a9baf5;
  font-size: 14px;
  width: 90px;
}