.reports-list {
  margin-top: 90px;
}

.report-card {
  display: flex;
  align-items: center;
  margin: 10px 20px;
  border-radius: 10px;
  background-color: white;
  padding-left: 10px;
  min-height: 75px;
}

.report-status {
  font-size: 13px;
  text-align: center;
  margin-top: 10px;
}

.report-data {
  flex-grow: 1;
  padding: 0px 10px 0px 10px;
  margin-left: 5px;
}

.report-date {
  flex: 1;
  font-size: small;
  font-weight: bold;
  color: rgb(68, 68, 68);
}

.report-product {
  font-weight: bolder;
  flex: 1;
  margin: 5px 0px;
}

.report-type {
  flex: 1;
  font-size: small;
}

.reports-search {
  display: flex;
  align-items: center;
  padding: 15px;
  margin-top: 90px;
}

.reports-search i {
  font-size: 30px;
  margin-right: 15px;
}