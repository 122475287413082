.header-wrapper {
  height: 40px;
}

.header {
  background-color: #faf9f8;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between; 
  height: 40px; 
  width: 100%;
  z-index: 1000;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(194,194,194,0.5);
  -moz-box-shadow: 2px 2px 5px 0px rgba(194,194,194,0.5);
  box-shadow: 2px 2px 5px 0px rgba(194,194,194,0.5);
}

.header .menu-button {
  padding: 10px 15px;
  cursor: pointer;
}

.main-menu-wrapper.show {
  display: block;
  left: 0px;
}

.main-menu-wrapper {
  position: fixed;
  left: -100%;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 10000000;
}

.main-menu {
  display: flex;
  flex-direction: column;
  padding: 15px 0px;
  box-sizing: border-box;
  background-color: white;
  z-index: 10000001;
  overflow: hidden;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 250px;
  height: 100%;
  margin: 0 0 0 -250px;
  -moz-transition: all 200ms ease-in;
  -webkit-transition: all 200ms ease-in;
  -o-transition: all 200ms ease-in;
  transition: all 200ms ease-in;  
}

.main-menu .menu-image {
  display: flex;
  align-items: center;
  padding: 15px;
}

.main-menu .menu-image div {
  text-transform: uppercase;
  color: #a0693e;
}

.main-menu .menu-image img {
  width: 50px;
  margin-right: 15px;
}

.main-menu ul {
  margin: 15px 0px;
  padding: 0px;  
}

.main-menu ul li {
  display: block;
  text-transform: uppercase;
  padding: 15px 15px 15px 25px;
  cursor: pointer;
  user-select: none;
  color: #44579c;
}

.main-menu ul li:hover, .main-menu ul li:focus {
  background-color: antiquewhite;
}

.main-menu.show {
  box-shadow: 0px 0px 15px 15px #8080802b;
  margin: 0;
}