.settings-list-item {
  font-size: 15px !important;
  color: #1c1c1c;
  margin-left: 10px;
  height: 35px;
  padding-left: 5px;
  padding-top: 5px;
  border-left: 1px solid #aaaaaa;
}

.settings-list-description {
  color: #3c3c3c;
}