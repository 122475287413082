
.title-list {
  display: flex;
  align-items: center;
  padding: 20px 20px;
  position: fixed;
  height: 40px;
  background-color: #faf9f8;
  color: rgb(10, 10, 10);
  width: 100%;
  margin-top: 0px;
}

.purchases-list {
  margin-top: 90px;
}

.purchases-search {
  display: flex;
  align-items: center;
  padding: 15px;
  margin-top: 90px;
}

.purchases-search i {
  font-size: 30px;
  margin-right: 15px;
}

.purchase-card {
  display: flex;
  align-items: center;
  margin: 10px 20px;
  border-radius: 10px;
  background-color: white;
  padding-left: 10px;
  min-height: 75px;
}

.purchase-card img {
  margin-right: 15px;
  margin-left: 10px;
}

.purchase-card_top {
  display: flex;
  margin: 5px 0px;
}

.purchase-data {
  flex-grow: 1;
  padding: 10px 10px 0px 10px;
  margin-left: 5px;
}

.purchase-date {
  flex: 1;
  font-size: small;
  font-weight: bolder;
  margin-bottom: 5px;
  color: rgba(24, 24, 24, 0.609);
}

.purchase-price {
  margin-right: 5px;
}

.purchase-status {
  font-size: 13px;
  text-align: right;
  margin-top: 10px;
}

.purchase-product {
  font-weight: bolder;
  flex: 1;
}

.purchase-address {
  font-size: small;
  font-weight: bold;
}

.empty-state {
  margin-left: 20px;
  color: rgb(49, 49, 49)
}