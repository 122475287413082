.splash-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100%;
  width: 100%;  
}

.splash-version {
  
}

.splash-screen img {
  margin-bottom: 20px;
  width: 80px;
}

.terms-conditions img {
  margin-bottom: 20px;
  width: 60px;
}