
.title {
  display: flex;
  align-items: center;
  padding: 10px 20px;
}

.title h1 {
  flex-grow: 1;
}

.title-logo-container img {
  width: 40px;
  height: 40px;
  padding: 15px;
  outline-offset: 3px;
  mix-blend-mode: darken;
  
}

.title-logo-container {
  border: 1px solid #C6CBE1;
  border-radius: 50%;
  width: 70px;
  height: 70px;
}

.center-padding {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
}

.center-padding span {
  padding-right: 15px;
}

.facebook-button {
  width: 100% !important;
  background-color: #FFF;
  border: solid 2px rgb(112, 112, 112) !important;
  color: #3b5998;
}

.facebook-button:hover {
  background-color: #3b5998;
  color: white;
  border: none !important;
}

.google-button {
  text-align: center;
  border-radius: 10px !important;
  box-shadow: none !important;
  background-color: rgb(255, 255, 255) !important;
  border: solid 2px rgb(112, 112, 112) !important;
  display: inline !important;
  font-weight: bolder !important;
  color: #DB4437 !important;
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: bold !important;
  font-size: 16px !important;

}

.google-button:hover {
  background-color: #DB4437 !important;
  color: white !important;
  transition: ease-in;
  border: none !important;
}

.google-button-div {
  text-align: center;
}

.buttons span {
  padding: 0px !important;
}

.buttons div {
  display: none;
}

.login-facebook span {
  width: 100%;
}

.login-facebook {
  padding-bottom: 0px !important;
}

.google-button::before {
  text-align: center;
}