body {
  color: #002a3a;
  background-color: #faf9f8;
}

body, h1, h2, h3, h4, div, p, span, input, button {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

h1, h2, h3 {
  margin: 3px;
}

h1 {
  font-size: 23px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 17px;
}

input {
  background-color: #f0f1f5;
  border: none;
  padding: 10px;
}

.fields-container {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}

.fields-container .row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.fields-container .field {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 30px; 
}

.fields-container .field input, .fields-container .field .select select {
  font-size: 16px;
  background-color: #faf9f8;
  border: none;
  border-bottom: 1px solid #cbd2d5; 
  height: 35px;
  padding: 0px 10px;
  flex-grow: 1;
  width: 100%;
  box-sizing: border-box;
}

.fields-container .field .select {
  display: flex;
}

.fields-container .field-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.fields-container .field-icon input {
  flex-grow: 1;
  padding-right: 40px;
}

.fields-container .field-icon span {
  position: absolute;
  right: 0px;
  padding: 8px;
}

.fields-container .field .radio {
  display: flex;
  flex-wrap: wrap;
}

.fields-container .field .radio .button {
  padding: 10px 10px;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 10px;
  font-weight: bolder;
  border: 1px solid #2451f6;
  color: #000000;
  white-space: nowrap;
  cursor: pointer;
}

.fields-container .field .radio .button.selected {
  background-color: #2451f6;
  color: #ffffff;
}

.fields-container .field .checkbox {
  display: flex;
  align-items: center;
}

.fields-container .field .checkbox .box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  border: 1px solid black;
  border-radius: 5px;
  margin: 5px 15px 5px 5px; 
}

.fields-container .field .checkbox .box.selected {
  background-color: #2451f6;
  border-color: lightgray;
  color: white;
}


.align-left {
  justify-content: left;
}

.search-area {
  display: flex;
  position: relative;
}

.search-area:before {
  color: #bcc0cc;
  position: absolute;
  left: 5px;
}

.search-area input {
  padding-left: 30px;
}

.clickable {
  cursor: pointer;
}

.buttons {
  display: flex;
  padding: 10px 15px;
}

.buttons button {
  flex-grow: 1;
  margin-right: 5px;
}

.buttons button:last-child {
  margin-right: 0px;
}

button {
  height: 45px;
  border-radius: 10px;
  font-weight: bolder;
  color: #000000;
  border: none;
  cursor: pointer;
}

button.primary {
  text-transform: uppercase;
  color: #ffffff;
  background-color: #2451f6;
}

button.add-funds {
  margin-right: 10px;
  width: 100%;
}

button.primary:disabled {
  background-color: #40424a;  
  color: #b1b1b1;
}

button.danger {
  text-transform: uppercase;
  color: #ffffff;
  background-color: #dc3545;
}

button.danger:disabled {
  background-color: #8f363f;  
  color: #b1b1b1;
}

button.secondary {
  background-color: #40424a;  
  color: white;
}

button.secondary:disabled {
  background-color: #40424a;  
  color: #b1b1b1;
}

button.flat {
  background-color: unset;
  color: #2451f6;
  padding: 10px 15px;
}

.link {
  color: #44579c;
  text-decoration: underline;
  cursor: pointer;
}

.error {
  color: red;
  padding: 5px;
}

.icon-color-red:before {
  color: red;
}

.icon-color-green:before {
  color: green;
}

.icon-color-gray:before {
  color: gray;
}

.icon-size-large:before {
  font-size: 70px;
}

.icon-size-medium:before {
  font-size: 45px;
}

.right {
  text-align: right;
}

.margin-top {
  margin-top: 15px;
}

.normal-padding {
  padding: 15px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  background-color: #ffffffa3;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 10000000;
}

.alert-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 50000000;
}

.alert {
  display: flex;
  flex-direction: column;
  position: fixed;
  min-width: 300px;
  max-width: 90%;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 2px 10px rgb(0, 0, 0, 0.3);
}

.alert button {
  border-radius: 0px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;  
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000000;
}

.modal-overlay {
  background-color: #50505047;
  width: 100%;
  height: 100%;
  position: fixed;
}

.modal-container {
  background-color: white;
  padding: 15px;
  z-index: 1000001;
  max-height: 100%;
  min-width: 80%;
  overflow-y: auto;  
  max-width: 85%;
  border-radius: 10px;
}

.spin:before {
  animation: spin 2s infinite linear;
}

.red:before {
  color: red;
}

.orange {
  color:#ff7c09;
}

.green {
  color:green;
}

.red {
  color:red;
}

.blue {
  color: #2451f6;
}

@keyframes spin {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

.scroll {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.scroll::-webkit-scrollbar {
  display: none; 
}

.error-modal {
  display: flex;
  align-items: center;
  padding-right: 15px;
  padding-left: 5px;
}

.error-modal i {
  font-size: 50px;
  margin-right: 15px;
}

.success-modal {
  display: flex;
  align-items: center;
  padding-right: 15px;
  padding-left: 5px;
}

.success-modal i {
  font-size: 50px;
  margin-right: 15px;
}

.confirm-modal {
  display: flex;
  align-items: center;
  padding-right: 15px;
  padding-left: 5px;
}

.confirm-modal i {
  font-size: 50px;
  margin-right: 15px;
}

.hidden {
  display: none;
}

.banner {
  text-align: center;
  font-size: 25px;
  font-weight: bolder;
  margin: 5px 15px;  
}

.banner.red {
  color: red;
}