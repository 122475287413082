@font-face {
  font-family: 'vendicenter-cashless';
  src: url('../font/vendicenter-cashless.eot?86294794');
  src: url('../font/vendicenter-cashless.eot?86294794#iefix') format('embedded-opentype'),
       url('../font/vendicenter-cashless.woff2?86294794') format('woff2'),
       url('../font/vendicenter-cashless.woff?86294794') format('woff'),
       url('../font/vendicenter-cashless.ttf?86294794') format('truetype'),
       url('../font/vendicenter-cashless.svg?86294794#vendicenter-cashless') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'vendicenter-cashless';
    src: url('../font/vendicenter-cashless.svg?86294794#vendicenter-cashless') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "vendicenter-cashless";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-eye:before { content: '\e800'; } /* '' */
.icon-heart:before { content: '\e801'; } /* '' */
.icon-heart-empty:before { content: '\e802'; } /* '' */
.icon-search:before { content: '\e803'; } /* '' */
.icon-bell:before { content: '\e804'; } /* '' */
.icon-cancel-circled:before { content: '\e805'; } /* '' */
.icon-info:before { content: '\e806'; } /* '' */
.icon-camera:before { content: '\e807'; } /* '' */
.icon-ok-circled:before { content: '\e808'; } /* '' */
.icon-cancel:before { content: '\e809'; } /* '' */
.icon-clock:before { content: '\e80a'; } /* '' */
.icon-credit-card:before { content: '\e80b'; } /* '' */
.icon-emo-coffee:before { content: '\e80c'; } /* '' */
.icon-attention:before { content: '\e80d'; } /* '' */
.icon-error:before { content: '\e80e'; } /* '' */
.icon-eye-off:before { content: '\e80f'; } /* '' */
.icon-mail:before { content: '\e810'; } /* '' */
.icon-ok:before { content: '\e811'; } /* '' */
.icon-spin:before { content: '\e839'; } /* '' */
.icon-crown-minus:before { content: '\e846'; } /* '' */
.icon-marquee:before { content: '\e847'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
.icon-coffee:before { content: '\f0f4'; } /* '' */
.icon-google:before { content: '\f1a0'; } /* '' */
.icon-facebook:before { content: '\f230'; } /* '' */
.icon-question:before { content: '\f29c'; } /* '' */