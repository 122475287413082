.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.confirmation-required {
  margin: 20px 20px 0px 20px;
  text-align: center;
  color: #00293a;
}

.distance-alert {
  padding: 10px 0px;
  text-align: center;
  color: #363636;
  font-size: 17px;
  width: 100%;
  background-color: #fbeb52;
  font-weight: bold;
}

.machine-list {
  margin-bottom: 100px;
  cursor: pointer;
}

.machine-summary {
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.machine-summary img {
  margin-right: 15px;
}

.machine-card {
  display: flex;
  align-items: center;
  margin: 10px 20px;
  border-radius: 10px;
  background-color: white;  
}

.machine-card img {
  margin-right: 15px;
  margin-left: 10px;
}

.machine-data {
  flex-grow: 1;
  padding: 10px 0px;
}

.machine-name {
  font-weight: bolder;
  margin-bottom: 5px;
}

.machine-address {
  font-size: smaller;
}

.machine-distance {
  font-size: smaller;
  color: gray;
}

.machine-favorite {
  color: red;
  padding: 8px;
}

.machine-connecting {
  font-weight: bolder;
  padding: 10px;
  text-align: center;
}

.machine-search {
  display: flex;
  align-items: center;
  padding: 15px;
}

.machine-search i {
  font-size: 50px;
  margin-right: 15px;
}

.machine-success:before {
  margin-right: 20px;  
}

.product-list {
  margin-bottom: 111px;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.items-container {
  flex: 100%;
  text-align: center;
}

.select-buttons {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.select-buttons-limited {
  width: 100%;
  max-width: 250px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}

.product-mini-card {
  flex: 1;
  flex-wrap: wrap;
  min-width: 75px;
  max-width: 23px;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 4% 1%;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  -webkit-box-shadow: 5px 5px 8px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.75);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.5);
}

.product-mini-card .product-name {
  font-size: 13px;
  word-wrap: break-word;
  padding: 0px 5px;
}

.product-keypad-mini-card {
  flex: 1;
  flex-wrap: wrap;
  min-width: 25%;
  max-width: 75px;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 4% 1%;
  padding: 5% 5px;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  -webkit-box-shadow: 5px 5px 8px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.75);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.5);
}

.mini-card-clickable {
  color: #0c0c0c;
}

.mini-card-unclickable {
  display: none;
}

.small-image {
  width: 100%;
}

.small-image img {
  width:  95%;
  object-fit: cover;
  margin-top: 5px;
  margin-right: 0px;
}

.select-message {
  display: flex;
  text-align: center;
  justify-content: center;
}

.no-margin {
  margin: 0 !important;
}

.product-card {
  display: flex;
  align-items: center;
  margin: 10px 20px;
  border-radius: 7px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.40);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.40);
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.40);
  cursor: pointer;
}

.product-image .image {
  width: 100px;
}

.product-card .image {
  width: 100px;
  height: 100px;
  min-width: 100px;
  background-color: transparent;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-card .image img {
  width:  90px;
  height: 90px;
  object-fit: cover;
}

.product-card .product-data {
  padding: 10px;  
}

.product-card .product-data .product-name {
  font-size: 16px;
  font-weight: bolder;  
}

.product-card .product-data .product-description {
  font-size: smaller;
  text-overflow: ellipsis;  
  overflow: hidden;
  max-height: 47px;
}

.product-card .product-data .product-existence.red {
  color: red;
}

.product-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

.product-view img {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: inherit;
}

.product-view .product-data .product-name {
  font-size: 20px;
  font-weight: bolder;  
  text-align: center;
}

.product-view .product-data .product-price, .product-view .product-data .product-position {
  font-weight: bolder;
  text-align: center;
}

.product-name {
  font-size: 18px !important;
}

.product-price {
  font-weight: bold;
}

.product-view .product-data .product-existence {
  text-align: center;
}

.product-view .product-data .product-description {
  padding: 10px 0px;
  text-align: center;
}

.product-view .product-data .product-existence.red {
  color: red;
}

.machine-keypad-input {
  align-items: center;
  margin: 0 auto;
  width: fit-content;
  background-color: #f0f1f5;
}

.machine-keypad-send {
  margin-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
  height: 30px;
}

.operators {
  min-width: 60px;
}

.operators-text {
  max-width: 100px;
  text-align: center;
}

.product-control-buttons {
  margin-top: -1em;
}

.select-product-control-message {
  margin-top: 20px;
}

.search-machines {
  display: inline-block;
}

.machines-error {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.machines-error-message {
  text-align: left;
}

.machines-search-not-found {
  text-align: center;
}